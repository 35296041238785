'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'contactless';
var width = 410;
var height = 512;
var ligatures = [];
var unicode = '';
var svgPathData = 'M312.6,510.8c-8.2-2.4-16.5-12.2-18.3-21.5-1.1-6.1.3-10.3,9-27.1,35.2-68.1,50.5-130.8,50.5-206.8s-13.5-130.7-47.2-199.3c-7.2-14.7-13.1-28.4-13.1-30.4,0-7.3,6.1-17.9,12.7-22,9.1-5.7,24.3-4.6,31.5,2.2,2.8,2.6,10.1,14.5,16.1,26.5,24.1,47.3,40.6,99.1,48.7,153.1,5,33.4,6,96.7,2,127.3-7.4,56.4-22.3,106.8-46.2,156.1-14.4,29.8-23.5,42.1-31,42.1s-4,.4-5.5.8c-1.5.5-5.7,0-9.2-1h0ZM214.6,459.5c-12.6-3.7-21.7-19.1-18.1-30.7.9-2.8,6.8-15.7,13.2-28.7,27-55,38.2-113.9,32.7-171.8-4.5-47.6-13-77.5-34.5-121.4-14.4-29.4-15.1-34-6.7-45.9,8-11.3,27.8-13.1,38.3-3.5,7.2,6.6,29.7,53.6,38.4,80.3,28.8,88.1,24.4,182.7-12.5,269.2-18.9,44.3-32.1,58-50.8,52.6ZM113.5,407.5c-7.8-3.5-14.5-11.7-16.3-20-1.1-5.1.8-10.6,10-29.4,33.9-68.7,34.3-134.9,1.1-202.1-14.6-29.5-14.9-35.4-2.6-47,6.1-5.7,8.7-6.8,17.2-6.8,14.3,0,21.8,7.2,34.6,33.5,19.7,40.5,27.8,75.6,27.7,120.5,0,46.2-7.6,77.9-28.6,120.6-12.1,24.4-18,30.9-30.1,32.5-3.7.5-9.5-.4-13-1.9h0ZM19.3,359.1c-6.2-2.6-13.2-10.9-15.3-18.2-2.3-8.2-.4-14.6,8.8-29.9,11.9-19.6,14.7-30.1,14.7-55.7s-2.8-36.1-14.7-55.7c-4-6.6-8.1-14.6-9-17.7-3.8-12.9,6.9-28.7,21.4-31.4,12.2-2.3,20.7,3.2,31.9,20.7,32.6,50.9,32.6,118.9,0,169.4-11.5,17.8-24.3,24.1-37.8,18.5h0Z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faContactless = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;